import A0 from "../../../../assets/img/topHome/1_0000.jpg";
import A1 from "../../../../assets/img/topHome/1_0001.jpg";
import A2 from "../../../../assets/img/topHome/1_0002.jpg";
import A3 from "../../../../assets/img/topHome/1_0003.jpg";
import A4 from "../../../../assets/img/topHome/1_0004.jpg";
import A5 from "../../../../assets/img/topHome/1_0005.jpg";
import A6 from "../../../../assets/img/topHome/1_0006.jpg";
import A7 from "../../../../assets/img/topHome/1_0007.jpg";
import A8 from "../../../../assets/img/topHome/1_0008.jpg";
import A9 from "../../../../assets/img/topHome/1_0009.jpg";
import A10 from "../../../../assets/img/topHome/1_00010.jpg";
import A11 from "../../../../assets/img/topHome/1_00011.jpg";
import A12 from "../../../../assets/img/topHome/1_00012.jpg";
import A13 from "../../../../assets/img/topHome/1_00013.jpg";
import A14 from "../../../../assets/img/topHome/1_00014.jpg";
import A15 from "../../../../assets/img/topHome/1_00015.jpg";
import A16 from "../../../../assets/img/topHome/1_00016.jpg";
import A17 from "../../../../assets/img/topHome/1_00017.jpg";
import A18 from "../../../../assets/img/topHome/1_00018.jpg";
import A19 from "../../../../assets/img/topHome/1_00019.jpg";
import A20 from "../../../../assets/img/topHome/1_00020.jpg";
import A21 from "../../../../assets/img/topHome/1_00021.jpg";
import A22 from "../../../../assets/img/topHome/1_00022.jpg";
import A23 from "../../../../assets/img/topHome/1_00023.jpg";
import A24 from "../../../../assets/img/topHome/1_00024.jpg";
import A25 from "../../../../assets/img/topHome/1_00025.jpg";
import A26 from "../../../../assets/img/topHome/1_00026.jpg";
import A27 from "../../../../assets/img/topHome/1_00027.jpg";
import A28 from "../../../../assets/img/topHome/1_00028.jpg";
import A29 from "../../../../assets/img/topHome/1_00029.jpg";
import A30 from "../../../../assets/img/topHome/1_00030.jpg";
import A31 from "../../../../assets/img/topHome/1_00031.jpg";
import A32 from "../../../../assets/img/topHome/1_00032.jpg";
import A33 from "../../../../assets/img/topHome/1_00033.jpg";
import A34 from "../../../../assets/img/topHome/1_00034.jpg";
import A35 from "../../../../assets/img/topHome/1_00035.jpg";
import A36 from "../../../../assets/img/topHome/1_00036.jpg";
import A37 from "../../../../assets/img/topHome/1_00037.jpg";
import A38 from "../../../../assets/img/topHome/1_00038.jpg";
// import A39 from "./1_00039.jpg";
// import A40 from "./1_00040.jpg";
// import A41 from "./1_00041.jpg";
// import A42 from "./1_00042.jpg";
// import A43 from "./1_00043.jpg";
// import A44 from "./1_00044.jpg";
// import A45 from "./1_00045.jpg";
// import A46 from "./1_00046.jpg";
// import A47 from "./1_00047.jpg";
// import A48 from "./1_00048.jpg";
// import A49 from "./1_00049.jpg";
// import A50 from "./1_00050.jpg";
// import A51 from "./1_00051.jpg";
// import A52 from "./1_00052.jpg";
// import A53 from "./1_00053.jpg";
// import A54 from "./1_00054.jpg";
// import A55 from "./1_00055.jpg";
// import A56 from "./1_00056.jpg";
// import A57 from "./1_00057.jpg";
// import A58 from "./1_00058.jpg";


export const AirPods = Array.of(
    A0,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14,
    A15,
    A16,
    A17,
    A18,
    A19,
    A20,
    A21,
    A22,
    A23,
    A24,
    A25,
    A26,
    A27,
    A28,
    A29,
    A30,
    A31,
    A32,
    A33,
    A34,
    A35,
    A36,
    A37,
    A38,
    // A39,
    // A40,
    // A41,
    // A42,
    // A43,
    // A44,
    // A45,
    // A46,
    // A47,
    // A48,
    // A49,
    // A50,
    // A51,
    // A52,
    // A53,
    // A54,
    // A55,
    // A56,
    // A57,
    // A58,
);
