import imageFeatured from './emgmt-1.jpg';
import image1 from './emgmt-2.jpg';
import image2 from './emgmt-3.jpg';
import image3 from './emgmt-4.jpg';
import image4 from './emgmt-5.jpg';
import image5 from './emgmt-6.jpg';
import image6 from './emgmt-7.jpg';
import image7 from './emgmt-8.jpg';
import image8 from './emgmt-9.jpg';


const imagesEmgmt = [
    [imageFeatured, imageFeatured],
    [image1, image1],
    [image2, image2],
    [image3, image3],
    [image4, image4],
    [image5, image5],
    [image6, image6],
    [image7, image7],
    [image8, image8],
]

export default imagesEmgmt;

