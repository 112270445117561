import imageFeatured from './elite-screenshosts1.jpg';
import image1 from './elite-screenshosts2.jpg';
import image2 from './elite-screenshosts3.jpg';
import image3 from './elite-screenshosts4.jpg';
import image4 from './elite-screenshosts5.jpg';
import image5 from './elite-screenshosts6.jpg';

const imagesElite = [
    [imageFeatured, imageFeatured],
    [image1, image1],
    [image2, image2],
    [image3, image3],
    [image4, image4],
    [image5, image5],
]

export default imagesElite;

