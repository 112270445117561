import React from 'react';
import ProjectItem from "./ProjectItem/ProjectItem";
import Slider from "../Slider/Slider";

const projects = [
    {
        name: 'hypertube',
        title: 'Hypertube',
        type: 'Cursus 42',
        demo: '',
        technos: ['react', 'nodejs', 'materialui', 'webpack', 'mongodb'],
        description: 'Application web permettant à un utilisateur de rechercher et visionner des vidéos téléchargées au travers du protocole BitTorrent.'
    },
    {
        name: 'elite',
        title: 'Elexte',
        type: 'Freelance',
        size: 'big',
        demo: '',
        technos: ['prestashop', 'nativejs', 'customtheme', 'seo', 'photoshop', 'illustrator'],
        description: "Multi-boutique regroupant plus de 500 produits d'extensions de cheveux. Migration Prestashop 1.6 vers 1.7 avec thème enfant customisé."
    },
    {
        name: 'eibschools',
        title: 'EIB Schools',
        type: 'Freelance',
        size: 'big',
        demo: '',
        technos: ['react', 'nodejs', 'gatsby', 'seo', 'photoshop', 'illustrator'],
        description: "Groupe d'écoles internationales privées. Sites internet basiques développés en React (avec gatsby), espace administration développé en NodeJS pour gérer les évènements, photos, ajout/suppression de fichiers accessibles aux parents."
    },
    {
        name: 'vulnchecker',
        title: 'Vuln Checker',
        type: 'Projet personnel',
        demo: '',
        technos: ['nodejs', 'bash'],
        description: "Projet personnel regroupant des failles connues du type exposed .git , qui a pour but d'automatiser les processus de recherche, de téléchargements et d'exploitation, à partir de nom de domaines et/ou d'une liste d'adresses IP."
    },
    {
        name: 'emgmt',
        title: 'Emgmt',
        type: 'Freelance',
        demo: '',
        technos: ['react', 'nodejs', 'redux', 'photoshop', 'illustrator'],
        description: "Mini plateforme de gestion de formulaires, avec un espace administration permettant d'approuver ou de refuser les demandes en retournant les champs erronés."
    },
    {
        name: 'cgmoto',
        title: 'CGMOTO',
        type: 'Stage cursus 42',
        demo: '',
        technos: ['PHP', 'html', 'css', 'nativejs'],
        description: "Une partie de mon stage de 6 mois où j'ai du modifier, adapter, découper plusieurs templates basées sur Bootstrap Stroyka pour ensuite les intégrer sur un compiler smarty."
    },
    {
        name: 'matcha',
        title: 'MatchApp',
        type: 'Cursus 42',
        demo: '',
        technos: ['react', 'nodejs', 'semantic', 'webpack', 'mysql'],
        description: 'MatchApp est une application web de rencontres, permettant à deux potentielles âmes soeurs de se rencontrer, de l’inscription au contact final.'
    },

   {
        name: 'esthelive',
        demo: '',
        title: 'Maquette Esthelive',
        type: 'Freelance',
        technos: ['photoshop', 'illustrator'],
        description: 'Maquette pré-développement qui consiste en la réalisation d’une plateforme sécurisée de téléconsultation entre médecins et patients dans le cadre d’opérations de chirurgie esthétique.'
    },
    // {
    //     name: 'zolushkat',
    //     demo: '',
    //     title: 'Zolushkat',
    //     type: 'Freelance',
    //     technos: ['react', 'photoshop', 'illustrator'],
    //     description: 'Boutique de vente de prêt-à-porter pour chats.'
    // },
    {
        name: 'camagru',
        demo: '',
        title: 'Camagru',
        type: 'Cursus 42',
        technos: ['PHP', 'mysql', 'MVC', 'nativejs'],
        description: 'Première application web. L\'utilisateur du site pourra sélectionner une image dans une liste d’images superposables, prendre une photo depuis sa webcam et admirer le résultat d’un montage digne\n' +
            'de James Cameron'
    }
]
const ProjectSection = () => {
    return (
        <>
            <section style={{opacity: 0}} className='projectSection fade-in-down'>
                <Slider />
                <div className='container'>
                    <div className='projectsWrapper'>
                        {
                            projects.map((item, index) => {
                                return (
                                    <ProjectItem
                                        key={index}
                                        reverse={false}
                                        size={item?.size ? item.size : ''}
                                        projectName={item.name}
                                        demo={item.demo}
                                        title={item.title}
                                        type={item.type}
                                        technos={item.technos}
                                        desc={item.description}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectSection;