import imageFeatured from './eibschools-1.jpg';
import image1 from './eibschools-2.jpg';
import image2 from './eibschools-3.jpg';
import image3 from './eibschools-4.jpg';
import image4 from './eibschools-5.jpg';
import image5 from './eibschools-6.jpg';
import image6 from './eibschools-7.jpg';
import image7 from './eibschools-8.jpg';
import image8 from './eibschools-9.jpg';
import image9 from './eibschools-10.jpg';

const imagesEIB = [
    [imageFeatured, imageFeatured],
    [image1, image1],
    [image2, image2],
    [image3, image3],
    [image4, image4],
    [image5, image5],
    [image6, image6],
    [image7, image7],
    [image8, image8],
    [image9, image9]
]

export default imagesEIB;

