import imageFeatured from './zolushkat1.jpg';
import image1 from './zolushkat2.jpg';
import image2 from './zolushkat3.jpg';
import image3 from './zolushkat4.jpg';
import image4 from './zolushkat5.jpg';


const imagesZolushkat = [
    [imageFeatured, imageFeatured],
    [image1, image1],
    [image2, image2],
    [image3, image3],
    [image4, image4],
]

export default imagesZolushkat;

