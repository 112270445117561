import imageFeatured from './cmoto-1.jpg';
import image1 from './cmoto-2.jpg';
import image2 from './cmoto-3.jpg';
import image3 from './cmoto-4.jpg';
import image4 from './cmoto-5.jpg';
import image5 from './cmoto-6.jpg';
import image6 from './cmoto-7.jpg';



const imagesCgmoto = [
    [imageFeatured, imageFeatured],
    [image1, image1],
    [image2, image2],
    [image3, image3],
    [image4, image4],
    [image5, image5],
    [image6, image6]
]

export default imagesCgmoto;

