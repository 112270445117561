import imageFeatured from './vulnchecker.jpg';




const imagesVulnchecker = [
    [imageFeatured, imageFeatured],
]

export default imagesVulnchecker;

